<template>
  <div class="map" id="map"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
  name: "Google-Map",
  props: {
    mapData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      googleApiKey: "AIzaSyDjuz2uMJoU6j7ZGkKgCvlXMH90dQad8iI", // ...8iI - TR Key
      mapStyles: [
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async initMap() {
      const loader = new Loader({
        apiKey: this.googleApiKey,
        version: "weekly",
        ...this.mapData.options,
      });

      const google = (window.google = window.google ? window.google : {});

      // Async load
      loader.load().then(async () => {
        const { Map } = await google.maps.importLibrary("maps");

        let map = new Map(document.getElementById("map"), {
          styles: this.mapStyles,
          ...this.mapData.settings,
        });

        // Marker (optional)
        let bounds = new google.maps.LatLngBounds();
        for (const markerData of this.mapData.markers) {
          let marker = new google.maps.Marker({
            map: map,
            position: new google.maps.LatLng(markerData.lat, markerData.lng),
            label: markerData.label,
            title: markerData.title,
            // icon: markerData.url
          });
          // Extend bounds over every marker in multiple (loop)
          if (this.mapData.fitBounds) {
            bounds.extend(marker.getPosition());
            map.fitBounds(bounds);
          }
        }

        // Path overlay
        const flightPlanCoordinates = [
          { lat: 37.772, lng: -122.214 },
          { lat: 21.291, lng: -157.821 },
          { lat: -18.142, lng: 178.431 },
          { lat: -27.467, lng: 153.027 },
        ];
        const flightPath = new google.maps.Polyline({
          path: flightPlanCoordinates,
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        flightPath.setMap(map);

        // Image Overlay
        const imageBounds = {
          north: 40.773941,
          south: 40.712216,
          east: -74.12544,
          west: -74.22655,
        };

        const historicalOverlay = new google.maps.GroundOverlay(
          "https://storage.googleapis.com/geo-devrel-public-buckets/newark_nj_1922-661x516.jpeg",
          imageBounds
        );
        historicalOverlay.setMap(map);
      });
    },
  },
  async created() {
    await this.initMap();
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  min-height: 500px;
  border: 1px solid var(--link_color_hover);
}

@media screen and (max-width: 700px) {
}
</style>
