<template>
  <div class="content contact">
    <banner title="Contact Us" :image="5"> </banner>

    <div class="section">
      <div class="content_wrapper">
        <h3>Emergency Line</h3>
        <p>1-800-919-9477</p>
      </div>
    </div>

    <div class="section">
      <div class="content_wrapper">
        <h3>Business Development</h3>
        <p>
          Colin Moe, VP Commercial Development<br />
          <a class="link" href="mailto:cmoe@taprootep.com"
            >cmoe@taprootep.com</a
          >
        </p>
      </div>
    </div>

    <div class="section">
      <div class="content_wrapper">
        <h3>General Inquiries</h3>
        <google-map :mapData="mapData"></google-map>

        <p>
          555 17th Street, Suite 800 <br />
          Denver, CO 80202 <br /><br />
          (303) 244-9626<br />
          <a class="link" href="mailto:info@taprootep.com"
            >info@taprootep.com</a
          >
          <img class="logo" src="@/assets/images/logo.svg" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import GoogleMap from "@/components/Map.vue";

export default {
  name: "Contact-view",
  components: {
    Banner,
    GoogleMap,
  },
  data() {
    return {
      mapData: {
        options: {},
        settings: {
          center: { lat: 39.74555, lng: -104.98937 },
          zoom: 12,
          maxZoom: 16,
          disableDefaultUI: false,
          scrollwheel: false,
          navigationControl: true,
          mapTypeControl: false,
          scaleControl: true,
          draggable: true,
          streetViewControl: false,
        },
        markers: [
          {
            label: "T",
            lat: 39.74555,
            lng: -104.98937,
          },
        ],
        fitBounds: false,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.logo {
  display: block;
  width: 155px;
  padding-top: 30px;
}

.layout {
  position: relative;
  &.left {
    width: 40%;
    float: left;
  }
  &.right {
    padding-left: 20px;
    width: 60%;
    float: right;
  }
}
@media all and (max-width: 700px) {
  .layout {
    &.left {
      width: 100%;
      float: none;
    }
    &.right {
      padding-left: 0px;
      width: 100%;
      float: none;
    }
  }
}
</style>
